import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

export const SbGuardRoute: CanActivateFn = (route, state) => {
  const router = inject(Router);
  
  const authString = localStorage.getItem('auth');
  if (!authString) {
    router.navigate(['/not-authorized']);
    return false;
  }

  const auth = JSON.parse(authString);

  if (auth?.roles?.includes('sb')) {
    return true;
  } else {
    router.navigate(['/not-authorized']);
    return false;
  }
};
