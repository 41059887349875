import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

// store
import { UtilitiesService } from './shared/services/utilities.service';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxPrinterModule } from 'ngx-printer';
import { ConnectivityService } from './shared/services/connectivity.service';
import { ImageService } from './shared/services/image.service';
import {
  ContractorsState,
  FlagsState,
  HolidaysState,
  TasksState,
  UsersState,
} from './store';
import { TokenService } from './shared/services/token.service';


@NgModule({
  declarations: [AppComponent],
  providers: [
    MessageService,
    UtilitiesService,
    ImageService,
    ConnectivityService,
    TokenService,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastModule,
    NgxsModule.forRoot([
      ContractorsState,
      HolidaysState,
      FlagsState,
      UsersState,
      TasksState
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxPrinterModule.forRoot({
      printOpenWindow: true,
      timeToWaitRender: 0,
    })
  ],
})
export class AppModule {}
