import { Injectable } from '@angular/core';
import { Flags } from '@app/shared/models/orders.model';
import { ApiService } from '@app/shared/services/api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

export class GetAllFlags {
  static readonly type = '[GetAllFlags] Get All';
}

export interface FlagsStateModel {
  flags: Flags[] | undefined;
  hasLoaded: boolean; // Track if flags are already loaded
}

@State<FlagsStateModel>({
  name: 'Flags',
  defaults: {
    flags: [],
    hasLoaded: false,
  },
})
@Injectable({ providedIn: 'root' })
export class FlagsState {
  constructor(private api: ApiService) {}

  @Selector()
  static selectFlags(state: FlagsStateModel): Flags[] | undefined {
    return state.flags;
  }

  @Selector()
  static hasLoaded(state: FlagsStateModel): boolean {
    return state.hasLoaded;
  }

  @Action(GetAllFlags)
  getAllFlags(ctx: StateContext<FlagsStateModel>) {
    const state = ctx.getState();

    // Check if flags have already been loaded
    if (state.hasLoaded) {
      return of(null); // Do nothing if already loaded
    }

    return this.api.get('api/flagcheckboxes').pipe(
      tap((response: Flags[]) => {
        ctx.setState({
          ...state,
          flags: response,
          hasLoaded: true, // Mark as loaded
        });
      }),
      catchError((error) => {
        console.error('Error fetching flags:', error);
        return of([]);
      })
    );
  }
}
