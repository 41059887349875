import { Injectable, inject } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap, of } from 'rxjs';

export class GetContractors {
  static readonly type = '[GetContractors] Get Contractors';
}

export type ContractorsStateModel = {
  contractors: any[] | undefined;
  hasLoaded: boolean; // Tracks if contractors are already fetched
};

@State<ContractorsStateModel>({
  name: 'contractors',
  defaults: {
    contractors: [],
    hasLoaded: false,
  },
})
@Injectable({ providedIn: 'root' })
export class ContractorsState {
  api = inject(ApiService);

  @Action(GetContractors)
  getContractors(ctx: StateContext<ContractorsStateModel>) {
    const state = ctx.getState();

    // Check if contractors have already been loaded
    if (state.hasLoaded) {
      return of(null); // Return an empty observable if already loaded
    }

    return this.api.get('api/contractors').pipe(
      tap((response: any) => {
        ctx.setState({
          ...state,
          contractors: response,
          hasLoaded: true, // Mark as loaded
        });
      })
    );
  }

  @Selector()
  static contractors(state: ContractorsStateModel) {
    return state.contractors;
  }

  @Selector()
  static hasLoaded(state: ContractorsStateModel) {
    return state.hasLoaded;
  }
}
