import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // core URLs
  private readonly avCoreUrl: string = environment.avCore;
  private readonly avImportUrl: string = environment.avImport;

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}

  /**
   * Helper to build the HTTP options object with custom headers.
   */
  private buildHttpOptions(
    headers?: HttpHeaders | { [header: string]: string | string[] }
  ) {
    return { headers };
  }

  /**
   * GET request.
   */
  get<T = any>(
    url: string,
    params?: Record<string, any>,
    headers?: HttpHeaders
  ): Observable<T> {
    return this.tokenService.getValidToken().pipe(
      switchMap((token) => {
        const httpParams = new HttpParams({ fromObject: params || {} });
        const options = this.buildHttpOptions({
          ...headers,
          Authorization: `Bearer ${token}`,
        });

        return this.httpClient.get<T>(`${this.avCoreUrl}/${url}`, {
          params: httpParams,
          ...options,
        });
      }),
      // If 401 occurs, handleError will refresh the token and retry the request
      catchError((error) => this.handleError(error, () => this.get<T>(url, params, headers)))
    );
  }

  /**
   * POST request.
   */
  post<T = any>(url: string, data: any, headers?: HttpHeaders): Observable<T> {
    return this.tokenService.getValidToken().pipe(
      switchMap((token) => {
        const options = this.buildHttpOptions({
          ...headers,
          Authorization: `Bearer ${token}`,
        });

        return this.httpClient.post<T>(`${this.avCoreUrl}/${url}`, data, options);
      }),
      catchError((error) => this.handleError(error, () => this.post<T>(url, data, headers)))
    );
  }

  /**
   * POST request (Import URL).
   */
  postImport<T = any>(url: string, data: any, headers?: HttpHeaders): Observable<T> {
    return this.tokenService.getValidToken().pipe(
      switchMap((token) => {
        const options = this.buildHttpOptions({
          ...headers,
          Authorization: `Bearer ${token}`,
        });

        return this.httpClient.post<T>(`${this.avImportUrl}/${url}`, data, options);
      }),
      catchError((error) => this.handleError(error, () => this.postImport<T>(url, data, headers)))
    );
  }

  /**
   * PUT request.
   */
  put<T = any>(url: string, data: any, headers?: HttpHeaders): Observable<T> {
    return this.tokenService.getValidToken().pipe(
      switchMap((token) => {
        const options = this.buildHttpOptions({
          ...headers,
          Authorization: `Bearer ${token}`,
        });

        return this.httpClient.put<T>(`${this.avCoreUrl}/${url}`, data, options);
      }),
      catchError((error) => this.handleError(error, () => this.put<T>(url, data, headers)))
    );
  }

  /**
   * DELETE request.
   */
  delete<T = any>(url: string, headers?: HttpHeaders): Observable<T> {
    return this.tokenService.getValidToken().pipe(
      switchMap((token) => {
        const options = this.buildHttpOptions({
          ...headers,
          Authorization: `Bearer ${token}`,
        });

        return this.httpClient.delete<T>(`${this.avCoreUrl}/${url}`, options);
      }),
      catchError((error) => this.handleError(error, () => this.delete<T>(url, headers)))
    );
  }

  /**
   * Central error handler for 401 (and other) HTTP errors.
   *
   * If the status is 401, attempt to refresh the token and then call `retryRequest()`.
   */
  private handleError(
    error: HttpErrorResponse,
    retryRequest: () => Observable<any>
  ): Observable<any> {
    // Only handle 401 (Unauthorized) here. Otherwise, rethrow the error.
    if (error.status === 401) {
      // If you store your refresh token in localStorage:
      const authToken = JSON.parse(localStorage.getItem('authToken') || 'null');
      const refreshToken = authToken?.refresh_token;

      if (!refreshToken) {
        // No refresh token, can't do anything - force logout or prompt login
        return throwError(() => new Error('No refresh token found. Please log in again.'));
      }

      // Attempt to refresh the token
      return this.tokenService.refreshToken(refreshToken).pipe(
        // If refreshToken succeeds, call the original request again
        switchMap(() => retryRequest()),
        catchError(() => {
          // If refresh also fails, throw an error or handle logout
          return throwError(() => new Error('Session expired. Please log in again.'));
        })
      );
    }

    // If not a 401 error, just propagate it.
    return throwError(() => error);
  }
}
