import { Routes } from '@angular/router';
import { SbGuardRoute } from './sb.guard';

export const SbRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./dashboard/dashboard.component').then(
        (c) => c.SBDashboardComponent
      ),
    canMatch: [SbGuardRoute],
  },
  {
    path: 'my-profile',
    loadComponent: () =>
      import('./my-profile/my-profile.component').then(
        (c) => c.MyProfileComponent
      ),
    canMatch: [SbGuardRoute],
  },
  {
    path: 'task/edit/:id',
    loadComponent: () =>
      import('@app/components/tasklist/edit-task/edit-task.component').then(
        (c) => c.EditTaskComponent
      ),
    canMatch: [SbGuardRoute],
  },
  {
    path: 'order',
    loadComponent: () =>
      import('./order/order.component').then((c) => c.OrderComponent),
    canMatch: [SbGuardRoute],
  },
  {
    path: 'new-order',
    loadComponent: () =>
      import('./order/create-order/create-order.component').then(
        (c) => c.CreateOrderComponent
      ),
    canMatch: [SbGuardRoute],
  },
  {
    path: 'edit/:id',
    loadComponent: () =>
      import('@app/components/edit-order/edit-order.component').then(
        (c) => c.EditOrderComponent
      ),
    canMatch: [SbGuardRoute],
  },
  {
    path: 'view/:id',
    loadComponent: () =>
      import('@app/components/view-order/view-order.component').then(
        (c) => c.ViewOrderComponent
      ),
    canMatch: [SbGuardRoute],
  },
  {
    path: 'sb-dashboard',
    loadChildren: () => [
      {
        path: '',
        loadComponent: () =>
          import('../sb/dashboard/dashboard.component').then(
            (c) => c.SBDashboardComponent
          ),
      },
      {
        path: 'new-order',
        loadComponent: () =>
          import('../sb/order/create-order/create-order.component').then(
            (c) => c.CreateOrderComponent
          ),
      },
      {
        path: 'view/:id',
        loadComponent: () =>
          import('../../components/view-order/view-order.component').then(
            (c) => c.ViewOrderComponent
          ),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import('../../components/edit-order/edit-order.component').then(
            (c) => c.EditOrderComponent
          ),
        //
      },
      {
        path: 'task/edit/:id',
        loadComponent: () =>
          import(
            '../../components/tasklist/edit-task/edit-task.component'
          ).then((c) => c.EditTaskComponent),
        //
      },
    ],
  },
  {
    path: 'sv-dashboard',
    loadChildren: () => [
      {
        path: '',
        loadComponent: () =>
          import('../sv/dashboard/dashboard.component').then(
            (c) => c.SVDashboardComponent
          ),
      },
      {
        path: 'view/:id',
        loadComponent: () =>
          import('../../components/view-order/view-order.component').then(
            (c) => c.ViewOrderComponent
          ),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import('../../components/edit-order/edit-order.component').then(
            (c) => c.EditOrderComponent
          ),
      },
      {
        path: 'task/edit/:id',
        loadComponent: () =>
          import(
            '../../components/tasklist/edit-task/edit-task.component'
          ).then((c) => c.EditTaskComponent),
      },
    ],
  },
  {
    path: 'sv-einteiler-dashboard',
    loadChildren: () => [
      {
        path: '',
        loadComponent: () =>
          import('../sv-enteiler/dashboard/dashboard.component').then(
            (c) => c.EnteilerDashComponent
          ),
      },
      {
        path: 'view/:id',
        loadComponent: () =>
          import('../../components/view-order/view-order.component').then(
            (c) => c.ViewOrderComponent
          ),
      },
      {
        path: 'edit/:id',
        loadComponent: () =>
          import('../../components/edit-order/edit-order.component').then(
            (c) => c.EditOrderComponent
          ),
      },
      {
        path: 'task/edit/:id',
        loadComponent: () =>
          import(
            '../../components/tasklist/edit-task/edit-task.component'
          ).then((c) => c.EditTaskComponent),
      },
    ],
  },
];
