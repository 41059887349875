import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, BehaviorSubject, catchError, throwError} from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private readonly avCoreUrl: string = environment.avCore;

  constructor(private http: HttpClient, private router: Router,) {}

  /**
   * Checks if the access token is expired and refreshes it if necessary.
   * @returns An Observable of the valid access token.
   */
  getValidToken(): Observable<string> {
    const token = localStorage.getItem('token') || '';
    const refreshToken = JSON.parse(localStorage.getItem('authToken') as string)?.refresh_token;

    if (this.isTokenExpired(token)) {
      return this.refreshToken(refreshToken).pipe(
        map((response: any) => {
          const newToken = response.access_token;
          localStorage.setItem('token', newToken);
          return newToken;
        })
      );
    } else {
      return new BehaviorSubject(token).asObservable();
    }
  }

  /**
   * Refreshes the access token using the refresh token.
   * @param refreshToken The refresh token.
   * @returns An Observable of the response containing the new access token.
   */

  public refreshToken(refreshToken: string) {
    const url = `${this.avCoreUrl}/api/auth/refresh`;

    return this.http.post(url, {
      refreshToken: refreshToken
    }).pipe(
      map((response: any) => {

        // Save updated auth response in local storage
        localStorage.setItem('authToken', JSON.stringify(response));
        localStorage.setItem('token', response.access_token);

        // Extract and return the new token from the response
        return response.access_token;
      }),
      catchError((error) => {

        // Navigate to login page and clean storage
        this.router.navigateByUrl('login')
        localStorage.clear();
        sessionStorage.clear();

        return throwError(() => new Error('Unable to refresh token. Please log in again.'));
      })
    );
  }

  /**
   * Checks if the token is expired.
   * @param token The access token.
   * @returns A boolean indicating whether the token is expired.
   */
  private isTokenExpired(token: string): boolean {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
}