import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap, of } from 'rxjs';

export class GetHolidays {
  static readonly type = '[GetHolidays] Get Holidays';
}

export interface Holiday {
  date: string;
  localName: string;
  name: string;
  countryCode: string;
  fixed: boolean;
  global: boolean;
  counties?: string[];
  launchYear?: number;
  types: string[];
}

export interface HolidaysStateModel {
  dates: Holiday[] | undefined;
  hasLoaded: boolean; // Track if holidays have been fetched
}

@State<HolidaysStateModel>({
  name: 'holidays',
  defaults: {
    dates: [],
    hasLoaded: false,
  },
})
@Injectable({ providedIn: 'root' })
export class HolidaysState {
  private http = inject(HttpClient);

  @Selector()
  static holidays(state: HolidaysStateModel): Holiday[] | undefined {
    return state.dates;
  }

  @Selector()
  static hasLoaded(state: HolidaysStateModel): boolean {
    return state.hasLoaded;
  }

  @Action(GetHolidays)
  getHolidays(ctx: StateContext<HolidaysStateModel>) {
    const state = ctx.getState();

    // Check if holidays have already been loaded
    if (state.hasLoaded) {
      return of(null); // Return an empty observable if already loaded
    }

    return this.http
      .get<Holiday[]>('https://date.nager.at/api/v3/NextPublicHolidays/AT')
      .pipe(
        tap((response: Holiday[]) => {
          ctx.setState({
            ...state,
            dates: response,
            hasLoaded: true, // Mark as loaded
          });
        }),
        catchError((error) => {
          console.error('Error fetching holidays:', error);
          return of([]); // Handle error by returning an empty array
        })
      );
  }
}
